<template>
    <div>
        <v-row>
            <v-col cols="12">
                <div class="d-flex justify-space-between">
                    <div style="padding: 18px 12px">
                        <div class="text-2xl"><i class="mdi mdi-account-group-outline"></i> ຈັດການຂໍ້ມູນລູກຄ້າ</div>
                    </div>
                    <v-breadcrumbs
                        class="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        :items="$route.meta.breadcrumb"
                        divider=">"
                        large
                    />

                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <v-btn color="green" outlined dark class="mx-1" @click="getAddData()" v-if="adddata">
                            <i class="mdi mdi-plus-circle-outline"></i> 
                            <span class="d-none d-md-block d-lg-block d-xl-block">ເພີ່ມຂໍ້ມູນລູກຄ້າ</span>
                        </v-btn>  
                        <v-btn color="primary" outlined dark class="mx-1" @click="getListData()">
                            <i class="mdi mdi-reload"></i> 
                            <span class="d-none d-md-block d-lg-block d-xl-block">ຮີໂຫລດ</span>
                        </v-btn>       
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="ຄົ້ນຫາ"
                            single-line
                            hide-details
                            style="max-width: 250px"
                        />
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="listdata"
                        :search="search"
                        :items-per-page="10"
                        :footer-props="{
                            'items-per-page-options': [10, 50, 100, -1],
                            showFirstLastPage: true,
                            'items-per-page-text': 'ຈຳນວນທີ່ສະແດງ:'
                        }"
                        class="elevation-1"
                        :loading="$store.state.auth.loading2"
                        loading-text="ກຳລັງໂຫລດ...ກະລຸນາລໍຖ້າ"
                        item-key="br_id"
                        v-model="selected"
                        fixed-header
                        height="530px"
                    >
                        <template v-slot:item.option="{ item }">
                            <div class="whitespace-nowrap">
                                <v-btn
                                    color="primary"
                                    fab
                                    small
                                    dark
                                    text 
                                    v-if="item.v_edit"
                                    @click="getEditData(item)"
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn
                                    color="error"
                                    fab
                                    small
                                    dark
                                    text
                                    v-if="item.v_delete"
                                    @click="getDeleteData(item)"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
            v-model="formDialog"
            persistent
            max-width="800px"
        >
            <v-card>
                <v-card-title class="amber lighten-1 black--text">
                    <span class="text-3xl"><i :class="mdicon"></i>{{mdtitle}}</span>
                </v-card-title>
                <v-card-text>
                    <v-form v-model="valid" ref="formdata" class="pt-2" lazy-validation>
                        <div class="border rounded mt-4 p-4">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field 
                                        type="text" 
                                        label="ຊື່ ແລະ ນາມສະກຸນ" 
                                        placeholder="ຊື່ ແລະ ນາມສະກຸນ" 
                                        outlined  
                                        dense
                                        v-model="formManage.fullname" 
                                        :rules="formManageRules.fullname" 
                                        required 
                                        append-icon="mdi-account-box-outline"  
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field 
                                        type="text" 
                                        label="ເບີໂທລະສັບ" 
                                        placeholder="ເບີໂທລະສັບ" 
                                        outlined  
                                        dense
                                        v-model="formManage.tel"
                                        :rules="formManageRules.tel" 
                                        required 
                                        append-icon="mdi-phone"  
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        label="ທີ່ຢູ່" 
                                        placeholder="ທີ່ຢູ່" 
                                        outlined  
                                        v-model="formManage.address" 
                                    />
                                </v-col>
                                
                            </v-row>
                        </div>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-container>
                        <v-row justify="end" class="p-1">
                            <v-btn class="mx-1" color="success" @click="saveData()"><i class="mdi mdi-content-save-outline"></i> ບັນທຶກ</v-btn>
                            <v-btn class="mx-1" color="error" @click="formDialog = false"><i class="mdi mdi-close-circle-outline"></i> ກັບຄືນ</v-btn>
                        </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                adddata: false,
                req_id: '',
                action: '',
                mdtitle: '',
                mdicon: '',
                valid: false,
                formDialog: false, 
                formManage: {
                    fullname: '',
                    tel: '',
                    address: '',
                },
                formManageRules: {
                    fullname: [
                        v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ',
                    ],
                    tel: [
                        v => !!v || 'ກະລຸນາປ້ອນຂໍ້ມູນກ່ອນ',
                    ],
                },
                headers: [
                    {
                        text: '#',
                        align: 'center',
                        sortable: false,
                        value: 'rno',
                        class: 'black--text title whitespace-nowrap'
                    },
                    {
                        text: 'ຊື່ ແລະ ນາມສະກຸນ',
                        align: 'start',
                        sortable: true,
                        value: 'cus_name',
                        class: 'black--text title whitespace-nowrap'
                    },
                    {
                        text: 'ເບີໂທລະສັບ',
                        align: 'start',
                        sortable: true,
                        value: 'cus_tel',
                        class: 'black--text title whitespace-nowrap'
                    },
                    {
                        text: 'ທີ່ຢູ່',
                        align: 'start',
                        sortable: true,
                        value: 'cus_address',
                        class: 'black--text title whitespace-nowrap'
                    },
                    {
                        text: 'ຕົວເລືອກ',
                        align: 'center',
                        sortable: false,
                        value: 'option',
                        class: 'black--text title whitespace-nowrap'
                    },
                ],
                selected: [],
                search: "",
                listdata: [],
            }
        },
        async created() {
            await this.getListData();
        },
        methods: {
            async getListData(){
                const formdata = await {
                    action: "listdata",
                    route: this.$route.name,
                    id: "",
                }
                this.action = await "add";
                this.listdata = await [];
                this.adddata = await false;
                await this.$store.commit("auth/setLoading2", true);
                const result = await this.$store.dispatch("manage/getManageCustomer", formdata);
                await this.$store.commit("auth/setLoading2", false);
                this.adddata = await (result['r_add'] == undefined) ? false : result['r_add'];
                if(result.data.length > 0){
                    this.listdata = await result.data;
                }
            },
            async getAddData(){
                this.action = await 'add';
                this.formManage = await {
                    fullname: '',
                    tel: '',
                    address: '',
                };
                this.req_id = '';
                this.mdtitle = await "ເພີ່ມຂໍ້ມູນລູກຄ້າ";
                this.mdicon = await "mdi mdi-plus-circle-outline";
                if(this.valid){
                    await this.$refs.formdata.resetValidation();
                }
                this.formDialog = await true;
            },
            async getEditData(data){
                this.action = await 'edit';
                this.formManage = await {
                    fullname: data.cus_name,
                    tel: data.cus_tel,
                    address: data.cus_address,
                };
                this.req_id = data.id;
                this.mdtitle = await "ແກ້ໄຂຂໍ້ມູນລູກຄ້າ";
                this.mdicon = await "mdi mdi-pencil";
                this.formDialog = await true;
            },
            async getDeleteData(data){
                this.$swal.fire({
                    title: 'ລຶບຂໍ້ມູນ',
                    text: 'ທ່ານຕ້ອງການລຶບຂໍ້ມູນ ຫລື ບໍ່ ?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#85ce61',
                    cancelButtonColor: '#F56C6C',
                    confirmButtonText: '<i class="mdi mdi-check-circle-outline"></i>  ຕົກລົງ',
                    cancelButtonText: '<i class="mdi mdi-close-circle-outline"></i>  ຍົກເລີກ'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await this.$store.commit("auth/setLoading", true);
                        const formdata = await {
                            action: 'deletedata',
                            id: data.id,
                        }
                        const result = await this.$store.dispatch("manage/getManageCustomer", formdata);
                        await this.$store.commit("auth/setLoading", false);
                        if(result.success){
                            this.formDialog = await false;
                            await this.getListData();
                            await this.$toast.fire(result.message,"","success");
                        }else{
                            this.$swal.fire("Oops",result.message,"warning");
                        }
                    }
                });
            },
            async saveData(){
                if(this.valid){
                    if(this.formManage.fullname == '' || this.formManage.tel == ''){
                        this.$refs.formdata.validate();
                        return false
                    }else{
                        this.$swal.fire({
                            title: 'ບັນທຶກຂໍ້ມູນ',
                            text: 'ທ່ານຕ້ອງການບັນທຶກຂໍ້ມູນ ຫລື ບໍ່ ?',
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonColor: '#85ce61',
                            cancelButtonColor: '#F56C6C',
                            confirmButtonText: '<i class="mdi mdi-check-circle-outline"></i>  ຕົກລົງ',
                            cancelButtonText: '<i class="mdi mdi-close-circle-outline"></i>  ຍົກເລີກ'
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                await this.$store.commit("auth/setLoading", true);
                                var result;
                                if(this.action == 'add'){
                                    const formdata = await {
                                        action: 'adddata',
                                        fullname: this.formManage.fullname,
                                        tel: this.formManage.tel,
                                        address: this.formManage.address,
                                    }
                                    result = await this.$store.dispatch("manage/getManageCustomer", formdata);
                                }else{
                                    const formdata = await {
                                        action: 'editdata',
                                        fullname: this.formManage.fullname,
                                        tel: this.formManage.tel,
                                        address: this.formManage.address,
                                        id: this.req_id,
                                    }
                                    result = await this.$store.dispatch("manage/getManageCustomer", formdata);
                                }
                                await this.$store.commit("auth/setLoading", false);
                                if(result.success){
                                    this.formDialog = await false;
                                    await this.getListData();
                                    await this.$toast.fire(result.message,"","success");
                                }else{
                                    this.$swal.fire("Oops",result.message,"warning");
                                }
                            }
                        });
                    }
                    
                }else{
                    this.$refs.formdata.validate();
                    return false
                }
                
            }
        },
    }
</script>